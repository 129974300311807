<svelte:options tag="daily-picks" />
<script>
	import {
		onMount
	} from 'svelte';
	import {
		firestore
	} from './app.js';
	let overlayDisplay = false;
	let singleArticle = {};
	let tabcontent = [];
	let teams = [];

	function openNav(article) {
		singleArticle = article;
		tabcontent = article.tabContent
		teams = article.teamsvsteam
		overlayDisplay = true;
	}

	function closeNav() {
		overlayDisplay = false;
	}

	onMount(() => {
		getArticles();
	});

	let articleList = [];

	/* Get Articles */
	function getArticles() {
		firestore.collection("daily_picks").
		get()
			.then(function (querySnapshot) {
				let byUsers = [];
				querySnapshot.forEach(function (doc) {
					let teamID = doc.id;
					let articleData = doc.data();
					articleData.id = teamID;
					byUsers.push(articleData);
				});
				articleList = byUsers;
			})
			.catch(function (error) {
				console.log(error);
				console.error('Error getting documents');
			});
	}
</script>
<section class="container" id="lvsbmajorevents">
	<div class="row">
		<ul class="picksContainer">
			{#each articleList as article , i }
     			<li>{article.date} - {article.title} - {@html article.htmlContent}</li>
     		 {/each}
	</ul>
    </div>
</section>


<style>
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
ul.picksContainer {
    display: flex;
    flex-direction: column;
}
ul.picksContainer li {
    display: inline-flex;
}
</style>